import { Pipe, PipeTransform } from '@angular/core';
import numeral from 'numeral';

@Pipe({
  name: 'numeral',
  standalone: true,
})
export class NumeralPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return numeral(value).format('0.[0a]');
  }
}
